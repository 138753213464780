

/* Modules
==================== */
// ドロワー
import drawer from "./modules/drawer";
drawer();

// スライダー
import swiper from "./modules/swiper";
swiper();

// ページトップ
import pagetop from "./modules/pagetop";
pagetop();

// スムーススクロール
import smoothScroll from "./modules/smoothScroll";
smoothScroll();

// スクロールイベント01
import scrollEvent01 from "./modules/scrollEvent01";
scrollEvent01();

// スクロールイベント02
import scrollEvent02 from "./modules/scrollEvent02";
scrollEvent02();

// モーダル
import modal from "./modules/modal";
modal();

// モーダル
import modalwindow from "./modules/modalwindow";
modalwindow();

// モーダル
import tab from "./modules/tab";
tab();
