export default function scrollEvent02() {

  const elements = [...document.querySelectorAll('.js-multi')];
  const options = {
    root: null,
    rootMargin: '0px 0px',
    threshold: 0
  };

  const observer = new IntersectionObserver(test, options);
  elements.forEach((element) => observer.observe(element));

  function test(entries) {
    const [{
      isIntersecting,
      target
    }] = entries;
    console.log(entries);

    if (isIntersecting) {
      const name = target.dataset.animation;
      active(name, target);
      observer.unobserve(target)
    }
  }

  function active(name, target) {

    if (name === 'multi') {
      target.classList.add('isMulti');
    }
    if (name === 'palent') {
      target.classList.add('isActive');
    };
  };

}
